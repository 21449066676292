@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: theme('colors.zinc.900');
}

.h-2-0 {
  background-color: theme('colors.purple.600');
}
